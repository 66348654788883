import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "./TATeams.module.scss";

const TATeams = (props) => {
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <div className={styles.TATeamsWrap} key={index}>
            <div className="SVINContainer">
              <div className={styles.header}>
                <h2>{data.header}</h2>
              </div>
              {data.IconLink ? (
                <div className={styles.TATeams}>
                  {data.IconLink.map((checkIcon, i) => (
                    <div key={i} className={styles.TATeamsCard}>
                      <div className={styles.TATeamsImg}>
                        <img
                          src={require(`../../images/LP/TATeams/${checkIcon.path}.png`)}
                        />
                      </div>
                      <div className={styles.TATeamsContent}>
                        <h4>{checkIcon.title}</h4>
                        <p>{checkIcon.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              {data.IconLink ? (
                <Carousel className={styles.TATeamsCardMob}>
                  {data.IconLink.map((checkIcon, i) => (
                    <Carousel.Item key={i} className={styles.TATeamsCard}>
                      <div className={styles.TATeamsImg}>
                        <img
                          src={require(`../../images/LP/TATeams/${checkIcon.path}.png`)}
                        />
                      </div>
                      <div className={styles.TATeamsContent}>
                        <h4>{checkIcon.title}</h4>
                        <p>{checkIcon.desc}</p>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : null}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TATeams;

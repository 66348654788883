import React from "react";
import Header from "../../../components/Landers/Header";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/ECommerceBrands";
import AllCheck from "../../../components/Landers/AllCheck";
import GetStarted from "../../../components/Landers/GetStarted";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import Footer from "../../../components/Landers/Footer";
import DataInfo from "../../../components/Data";
import styles from "../../../components/Landers/landers.module.scss";
import TATeams from "../../../components/TATeams";
import SectionTemplate from "../../../components/Landers/SectionTemplate";

const testimonials = [
  {
    name: "Rupasi M",
    company: "Datarynx Pvt. Ltd.",
    p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
  },
  {
    name: "Ravindra M",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
  },
];

export default () => (
  <div className={styles.eCommerce}>
    <SEO
      title="Best Employment Screening Service for E-commerce Companies | SpringVerify India"
      desc="Verify the employment history of your candidates for your E-commerce company with SpringVerify's Instant Verification Solutions."
      url="https://in.springverify.com/industry/e-commerce/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/solution/SV-IN+(Ecommerce).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={`Background Screening \nSolutions for \nE-commerce Industry`}
        p="Screen your employees, third-party vendors, and logistics personnel for a safe and secure workplace with SpringVerify’s robust screening solutions."
        img="https://assets-sv-in.gumlet.io/LP/e-commerce/banner-illustration.svg"
      />
    </div>
    <Brands />
    <section>
      <SectionTemplate
        img="e-commerce/bgScreening.svg"
        h2={`Need for Background \nScreening in the \nE-commerce Industry`}
        p={`The E-commerce industry has single-handedly changed people’s lifestyles by allowing them to view,
        sell and buy goods online. And due to closer proximity with the general public, a thorough screening process is
        necessary when it comes to hiring in large volumes. Here’s why background screening is important,`}
        ul={[
          "Third-party vendors are thoroughly screened for a reliable workforce",
          "To maintain trust safeguard brand reputation",
          "Hiring qualified candidates who are ideal",
          "Avoid any mishap at customer-facing touchpoints during deliveries and returns",
        ]}
      />
    </section>
    <AllCheck data={DataInfo.ECommerceAllCheck} />
    <section>
      <SectionTemplate
        img="e-commerce/rightOption.svg"
        h2={`Why is SpringVerify the \nright option?`}
        p={`SpringVerify is the forerunner when it comes to instant background verification. Here’s why`}
        ul={[
          "Real-time verification",
          "Best turnaround time in the industry",
          "Seamless integration to your HR systems",
          "Transparent & Flexible pricing",
          "Lifetime validity of credits",
          "Solid documentation of screening reports",
        ]}
      />
    </section>
    <TATeams data={DataInfo.ECommerceTATeams} />
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="e-commerce/ecGetstartedNew.svg"
      h3={`Start screening with an \nindustry leader in \nbackground verification.`}
    />
    <Footer />
  </div>
);
